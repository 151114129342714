import React, { useContext, useEffect, useState } from "react";
import { AuthContext } from "../contexts/AuthContext";
import { useNavigate } from "react-router-dom";
import axios from "./../axiosConfig";
import { API_URL } from "./../config";

const profiles = {
  default: {
    values: ["Politeness", "Clarity"],
    styles: ["Formal", "Engaging"],
  },
  professional: {
    values: ["Professionalism", "Conciseness"],
    styles: ["Formal"],
  },
  friendly: {
    values: ["Friendliness", "Helpfulness"],
    styles: ["Engaging"],
  },
};

const languages = ["french", "english", "spanish", "italian"];

const retryRequest = async (fn, retries = 3, delay = 1000) => {
  for (let i = 0; i < retries; i++) {
    try {
      return await fn();
    } catch (error) {
      if (i === retries - 1) throw error;
      await new Promise((resolve) => setTimeout(resolve, delay));
    }
  }
};

const Loading = () => (
  <div className="loading-spinner">
    {/* Add your loading spinner or animation here */}
    Loading...
  </div>
);

function ChromeExtension() {
  const [text, setText] = useState("this is a test");
  const [originalHtml, setOriginalHtml] = useState("");
  const [explanation, setExplanation] = useState("");
  const [evaluations, setEvaluations] = useState([]);
  const [styles, setStyles] = useState([]);
  const [evaluationsEnhanced, setEvaluationsEnhanced] = useState([]);
  const [stylesEnhanced, setStylesEnhanced] = useState([]);
  const [selectedProfile, setSelectedProfile] = useState("default");
  const [selectedLanguage, setSelectedLanguage] = useState("french");
  const [isEnhanceVisible, setIsEnhanceVisible] = useState(false);
  const [loading, setLoading] = useState(false);

  const { authToken, refreshToken, logout } = useContext(AuthContext);
  const navigate = useNavigate();

  useEffect(() => {
    console.log("im authenticated ?");
    console.log("authToken ", authToken);
    if (!authToken) {
      navigate("/loginchromeextension");
      return;
    }

    const fetchData = async () => {
      try {
        const response = await axios.get(`${API_URL}/test-auth`, {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        });
        console.log(response.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, [authToken, navigate]);

  useEffect(() => {
    const intervalId = setInterval(async () => {
      try {
        const newToken = await refreshToken(); // This now also updates the context
      } catch (error) {
        console.error("Failed to refresh token:", error);
      }
    }, 120000); // 2 minutes

    return () => clearInterval(intervalId); // Cleanup on unmount
  }, [refreshToken]);

  useEffect(() => {
    function handleMessage(event) {
      if (event.data.action === "getText") {
        console.log("Sending text to parent:", text);
        window.parent.postMessage(
          {
            action: "setText",
            text: text,
            source: "pgnnjnodjilhhbjgoideoagfodepkkbo",
          },
          event.origin
        );
      } else if (event.data.action === "setInitialText") {
        console.log("Setting initial text from parent:", event.data.text);
        setText(event.data.text);
      } else {
        console.log("Unknown action received:", event.data.action);
      }
    }

    window.addEventListener("message", handleMessage);
    console.log("Event listener for message added");

    return () => {
      window.removeEventListener("message", handleMessage);
      console.log("Event listener for message removed");
    };
  }, [text]);

  const handleSpellcheck = async () => {
    setLoading(true);
    try {
      const response = await retryRequest(() =>
        axios.post(
          `${API_URL}/api/spell_check`,
          { text },
          {
            headers: {
              Authorization: `Bearer ${authToken}`,
              "Content-Type": "application/json",
            },
          }
        )
      );
      const data = response.data;
      setOriginalHtml(data.original_html);
      setText(data.corrected_text);
      setExplanation(data.explanation);
    } catch (error) {
      console.error("Error during spell check:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleEnhance = async () => {
    setLoading(true);
    const profile = profiles[selectedProfile];
    const requestData = {
      text_content: text,
      value_arr: profile.values,
      style_arr: profile.styles,
      language: selectedLanguage,
    };

    try {
      const response = await retryRequest(() =>
        axios.post(`${API_URL}/api/evaluate_text`, requestData, {
          headers: {
            Authorization: `Bearer ${authToken}`,
            "Content-Type": "application/json",
          },
        })
      );
      const data = response.data;
      setEvaluations(data.evaluations);
      setStyles(data.styles);
      setText(data.enhanced_text);
      setEvaluationsEnhanced(data.evaluations_enhanced_text);
      setStylesEnhanced(data.styles_enhanced_text);
      setIsEnhanceVisible(true);
    } catch (error) {
      console.error("Error during text enhancement:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (isEnhanceVisible) {
      handleEnhance();
    }
  }, [selectedProfile, selectedLanguage]);

  return (
    <div>
      <h1>scribebot</h1>
      {loading ? (
        <Loading />
      ) : (
        <>
          {isEnhanceVisible && (
            <div>
              <label>
                Profile:
                <select
                  value={selectedProfile}
                  onChange={(e) => setSelectedProfile(e.target.value)}
                >
                  {Object.keys(profiles).map((profileKey) => (
                    <option key={profileKey} value={profileKey}>
                      {profileKey}
                    </option>
                  ))}
                </select>
              </label>
              <label>
                Language:
                <select
                  value={selectedLanguage}
                  onChange={(e) => setSelectedLanguage(e.target.value)}
                >
                  {languages.map((lang) => (
                    <option key={lang} value={lang}>
                      {lang}
                    </option>
                  ))}
                </select>
              </label>
            </div>
          )}
          {originalHtml && (
            <div
              dangerouslySetInnerHTML={{ __html: originalHtml }}
              style={{
                marginBottom: "1rem",
                border: "1px solid #ccc",
                padding: "1rem",
              }}
            />
          )}
          <textarea value={text} onChange={(e) => setText(e.target.value)} />
          <div style={{ marginTop: "1rem" }}>
            <button onClick={handleSpellcheck}>Spellcheck</button>
            <button onClick={handleEnhance}>Enhance</button>
            {/* Add buttons for Translate here if needed */}
          </div>
          {explanation && (
            <div
              style={{
                marginTop: "1rem",
                border: "1px solid #ccc",
                padding: "1rem",
              }}
            >
              <strong>Explanation:</strong>
              <p>{explanation}</p>
            </div>
          )}
          {evaluations.length > 0 && (
            <div
              style={{
                marginTop: "1rem",
                border: "1px solid #ccc",
                padding: "1rem",
              }}
            >
              <h2>Evaluations</h2>
              {evaluations.map((evaluation, index) => (
                <p key={index}>
                  <strong>{evaluation.value}:</strong> {evaluation.score} -{" "}
                  {evaluation.reason}
                </p>
              ))}
            </div>
          )}
          {styles.length > 0 && (
            <div
              style={{
                marginTop: "1rem",
                border: "1px solid #ccc",
                padding: "1rem",
              }}
            >
              <h2>Styles</h2>
              {styles.map((style, index) => (
                <p key={index}>
                  <strong>{style.style}:</strong> {style.score} - {style.reason}
                </p>
              ))}
            </div>
          )}
          {evaluationsEnhanced.length > 0 && (
            <div
              style={{
                marginTop: "1rem",
                border: "1px solid #ccc",
                padding: "1rem",
              }}
            >
              <h2>Enhanced Evaluations</h2>
              {evaluationsEnhanced.map((evaluation, index) => (
                <p key={index}>
                  <strong>{evaluation.value}:</strong> {evaluation.score} -{" "}
                  {evaluation.reason}
                </p>
              ))}
            </div>
          )}
          {stylesEnhanced.length > 0 && (
            <div
              style={{
                marginTop: "1rem",
                border: "1px solid #ccc",
                padding: "1rem",
              }}
            >
              <h2>Enhanced Styles</h2>
              {stylesEnhanced.map((style, index) => (
                <p key={index}>
                  <strong>{style.style}:</strong> {style.score} - {style.reason}
                </p>
              ))}
            </div>
          )}
        </>
      )}
    </div>
  );
}

export default ChromeExtension;
