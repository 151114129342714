import React, { useContext } from "react";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import Dashboard from "./components/Dashboard";
import Login from "./components/Login";
import AuthProvider, { AuthContext } from "./contexts/AuthContext";
import axios from "axios";
import "./i18n";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ChromeExtension from "./components/chromeextension.js";
import AuthHandlerChromeExtension from "./contexts/authhandlerchromeextension.js";
import LoginChromeExtension from "./components/LoginChromeExtension.js";
import Layout from "./components/layout.js";
// import ReactGA from "react-ga4";

// Initialize Google Analytics
// ReactGA.initialize("G-P482RK96MY"); // Replace with your actual tracking ID

axios.defaults.withCredentials = true;

const AuthHandler = ({ children }) => {
  const { authToken, loading } = useContext(AuthContext); // Use useContext to get authToken and loading

  if (loading) {
    return <div>Loading...</div>; // Or any loading indicator
  }

  if (!authToken) {
    return <Navigate to="/login" />;
  }

  return children;
};

function App() {
  /*
  const location = useLocation();

  useEffect(() => {
    // Log initial page load
    ReactGA.pageview(location.pathname + location.search);
  }, [location]);
  */

  return (
    <BrowserRouter>
      <Layout>
        <AuthProvider>
          <Routes>
            <Route path="/login" element={<Login />} />
            <Route
              path="/dashboard/*"
              element={
                <AuthHandler>
                  <Dashboard />
                </AuthHandler>
              }
            />
            <Route
              path="/loginchromeextension"
              element={<LoginChromeExtension />}
            />
            <Route
              path="/chromeextension"
              element={
                <AuthHandlerChromeExtension>
                  <ChromeExtension />
                </AuthHandlerChromeExtension>
              }
            />
          </Routes>
          <ToastContainer />
        </AuthProvider>
      </Layout>
    </BrowserRouter>
  );
}

export default App;
