// Layout.js
import React, { useEffect } from "react";
import { Helmet, HelmetProvider } from "react-helmet-async";

const Layout = ({ children }) => {
  useEffect(() => {
    const initializeAmplitude = () => {
      if (
        window.amplitude &&
        window.sessionReplay &&
        window.amplitudeAutocapturePlugin
      ) {
        window.amplitude
          .add(window.sessionReplay.plugin({ sampleRate: 1 }))
          .promise.then(() => {
            window.amplitude.add(window.amplitudeAutocapturePlugin.plugin());
            window.amplitude.init("ab7751881d036fb9e50cbf02679c9b02");
          });
      }
    };

    // Check periodically if amplitude is loaded
    const intervalId = setInterval(() => {
      if (
        window.amplitude &&
        window.sessionReplay &&
        window.amplitudeAutocapturePlugin
      ) {
        initializeAmplitude();
        clearInterval(intervalId);
      }
    }, 100);

    // Cleanup interval on component unmount
    return () => clearInterval(intervalId);
  }, []);

  return (
    <HelmetProvider>
      <div className="Layout">
        <Helmet>
          <script src="https://cdn.amplitude.com/libs/analytics-browser-2.7.4-min.js.gz"></script>
          <script src="https://cdn.amplitude.com/libs/plugin-session-replay-browser-1.4.1-min.js.gz"></script>
          <script src="https://cdn.amplitude.com/libs/plugin-autocapture-browser-0.9.0-min.js.gz"></script>
        </Helmet>
        {children}
      </div>
    </HelmetProvider>
  );
};

export default Layout;
