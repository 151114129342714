import React, { useState, useContext } from "react";
import axios from "axios";
import { AuthContext } from "../contexts/AuthContext";
import { API_URL } from "./../config";
import { useTranslation } from "react-i18next";

function Translate() {
  const { t } = useTranslation();
  const [text, setText] = useState("");
  const [language, setLanguage] = useState("french");
  const [translatedText, setTranslatedText] = useState("");
  const { authToken, refreshToken } = useContext(AuthContext);

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      const response = await axios.post(
        `${API_URL}/api/translateV2`,
        {
          text,
          language,
        },
        {
          headers: {
            Authorization: `Bearer ${authToken}`,
            "Content-Type": "application/json",
          },
        }
      );
      setTranslatedText(response.data.translation);
    } catch (error) {
      console.error("Translation failed:", error);
      setTranslatedText(t("translationFailed"));
    }
  };

  return (
    <div className="w-full h-full p-4 bg-primaryBackground">
      <div className="bg-alternate shadow-lg p-5 rounded-lg w-full">
        <h2 className="text-xl font-semibold font-roboto ">
          {t("translateText")}
        </h2>
        <form onSubmit={handleSubmit} className="mt-4">
          <div className="mb-4">
            <label
              htmlFor="text"
              className="block text-sm font-medium text-gray-700"
            >
              {t("text")}
            </label>
            <textarea
              id="text"
              value={text}
              onChange={(e) => setText(e.target.value)}
              rows="5"
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
            />
          </div>
          <div className="mb-4">
            <label
              htmlFor="language"
              className="block text-sm font-medium text-gray-700"
            >
              {t("language")}
            </label>
            <select
              id="language"
              value={language}
              onChange={(e) => setLanguage(e.target.value)}
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
            >
              <option value="french">{t("french")}</option>
              <option value="spanish">{t("spanish")}</option>
              <option value="german">{t("german")}</option>
            </select>
          </div>
          <button
            type="submit"
            className="px-4 py-2 bg-indigo-600 hover:bg-indigo-700 text-white font-bold rounded"
          >
            {t("translate")}
          </button>
        </form>
        <p className="mt-4">
          {t("translatedText")}:{" "}
          <span className="font-medium">{translatedText}</span>
        </p>
      </div>
    </div>
  );
}

export default Translate;
