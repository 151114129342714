import React, { useState, useContext, useRef, useEffect } from "react";
import axios from "./../axiosConfig";
import { AuthContext } from "../contexts/AuthContext";
import { API_URL } from "./../config";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faMinusCircle,
  faPaste,
  faCopy,
} from "@fortawesome/free-solid-svg-icons";
import { Tooltip } from "react-tooltip";

const profiles = {
  default: {
    values: ["Politeness", "Clarity"],
    styles: ["Formal", "Engaging"],
  },
  professional: {
    values: ["Professionalism", "Conciseness"],
    styles: ["Formal"],
  },
  friendly: {
    values: ["Friendliness", "Helpfulness"],
    styles: ["Engaging"],
  },
};

function EmailEvaluation() {
  const { t } = useTranslation();
  const { authToken } = useContext(AuthContext);
  const [emailContent, setEmailContent] = useState("");
  const [values, setValues] = useState(profiles.default.values);
  const [styles, setStyles] = useState(profiles.default.styles);
  const [newValue, setNewValue] = useState("");
  const [newStyle, setNewStyle] = useState("");
  const [evaluationResults, setEvaluationResults] = useState(null);
  const [enhancedEmailContent, setEnhancedEmailContent] = useState("");
  const [enhancedEmailEvaluations, setEnhancedEmailEvaluations] = useState([]);
  const [enhancedEmailStyles, setEnhancedEmailStyles] = useState([]);
  const [selectedProfile, setSelectedProfile] = useState("default");
  const [makeItLonger, setMakeItLonger] = useState(false);
  const [makeItShorter, setMakeItShorter] = useState(false);
  const [addingValues, setAddingValues] = useState(false);
  const [addingStyles, setAddingStyles] = useState(false);
  const [editingProfile, setEditingProfile] = useState(false);
  const [isEvaluating, setIsEvaluating] = useState(false);

  const emailContentRef = useRef(null);
  const enhancedEmailRef = useRef(null);
  const enhancedEmailTextareaRef = useRef(null);

  const handleAddValue = () => {
    if (newValue && !values.includes(newValue)) {
      setValues([...values, newValue]);
      setNewValue("");
    }
  };

  const handleDeleteValue = (valueToDelete) => {
    setValues(values.filter((v) => v !== valueToDelete));
  };

  const handleAddStyle = () => {
    if (newStyle && !styles.includes(newStyle)) {
      setStyles([...styles, newStyle]);
      setNewStyle("");
    }
  };

  const handleDeleteStyle = (styleToDelete) => {
    setStyles(styles.filter((s) => s !== styleToDelete));
  };

  const handleProfileChange = (event) => {
    const profile = event.target.value;
    setSelectedProfile(profile);
    setValues(profiles[profile].values);
    setStyles(profiles[profile].styles);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsEvaluating(true);

    const maxRetries = 3;
    let attempts = 0;
    let success = false;

    while (attempts < maxRetries && !success) {
      try {
        const response = await axios.post(
          `${API_URL}/api/evaluate_emailV3`,
          {
            email_content: emailContent,
            value_arr: values,
            style_arr: styles,
          },
          {
            headers: {
              Authorization: `Bearer ${authToken}`,
              "Content-Type": "application/json",
            },
          }
        );
        setEvaluationResults(response.data);
        setEnhancedEmailContent(response.data.enhanced_email);
        setEnhancedEmailEvaluations(
          response.data.evaluations_enhanced_email || []
        );
        setEnhancedEmailStyles(response.data.styles_enhanced_email || []);
        success = true;
        setIsEvaluating(false);

        setTimeout(() => {
          enhancedEmailRef.current.scrollIntoView({ behavior: "smooth" });
        }, 500);
      } catch (error) {
        attempts++;
        if (attempts >= maxRetries) {
          console.error(
            "Email evaluation failed after multiple attempts:",
            error
          );
          setEvaluationResults(null);
          setIsEvaluating(false);
        } else {
          console.log(`Retrying... (${attempts}/${maxRetries})`);
        }
      }
    }
  };

  const handleGenerateBetter = async () => {
    setEmailContent(enhancedEmailContent);

    if (evaluationResults && evaluationResults.enhanced_email) {
      const maxRetries = 3;
      let attempts = 0;
      let success = false;

      while (attempts < maxRetries && !success) {
        try {
          const payload = {
            email_content: enhancedEmailContent,
            value_arr: values,
            style_arr: styles,
          };

          if (makeItLonger) {
            payload.mylength = "longer";
          } else if (makeItShorter) {
            payload.mylength = "shorter";
          }

          const response = await axios.post(
            `${API_URL}/api/evaluate_emailV3`,
            payload,
            {
              headers: {
                Authorization: `Bearer ${authToken}`,
                "Content-Type": "application/json",
              },
            }
          );
          setEvaluationResults(response.data);
          setEnhancedEmailContent(response.data.enhanced_email);

          if (response.data.enhanced_evaluations) {
            setEnhancedEmailEvaluations(response.data.enhanced_evaluations);
          }
          if (response.data.styles_enhanced_email) {
            setEnhancedEmailStyles(response.data.styles_enhanced_email);
          }
          success = true;
        } catch (error) {
          attempts++;
          if (attempts >= maxRetries) {
            console.error(
              "Email evaluation failed after multiple attempts:",
              error
            );
            setEvaluationResults(null);
          } else {
            console.log(`Retrying... (${attempts}/${maxRetries})`);
          }
        }
      }
    }
  };

  const handleCheckboxChange = (type) => {
    if (type === "longer") {
      setMakeItLonger(!makeItLonger);
      if (makeItShorter) setMakeItShorter(false);
    } else if (type === "shorter") {
      setMakeItShorter(!makeItShorter);
      if (makeItLonger) setMakeItLonger(false);
    }
  };

  const toggleEditProfile = () => {
    setEditingProfile(!editingProfile);
    setAddingValues(false);
    setAddingStyles(false);
  };

  const handlePasteFromClipboard = async () => {
    try {
      const text = await navigator.clipboard.readText();
      setEnhancedEmailContent(text);
    } catch (error) {
      console.error("Failed to read clipboard contents:", error);
    }
  };

  const handlePasteFromClipboardEmailContent = async () => {
    try {
      const text = await navigator.clipboard.readText();
      setEmailContent(text);
    } catch (error) {
      console.error("Failed to read clipboard contents:", error);
    }
  };

  const handleCopyToClipboard = () => {
    navigator.clipboard.writeText(enhancedEmailContent).then(
      () => {
        console.log("Enhanced email content copied to clipboard");
      },
      (error) => {
        console.error("Failed to copy email content to clipboard:", error);
      }
    );
  };

  const handleCopyToClipboardEmailContent = () => {
    navigator.clipboard.writeText(emailContent).then(
      () => {
        console.log("Email content copied to clipboard");
      },
      (error) => {
        console.error("Failed to copy email content to clipboard:", error);
      }
    );
  };

  useEffect(() => {
    if (enhancedEmailContent && enhancedEmailTextareaRef.current) {
      const timer = setTimeout(() => {
        enhancedEmailTextareaRef.current.classList.remove(
          "animate-borderColorChange"
        );
      }, 3000);
      return () => clearTimeout(timer);
    }
  }, [enhancedEmailContent]);

  const handleFocus = (ref) => {
    console.log("handleFocus");
    if (ref.current) {
      ref.current.focus();
      console.log("focused");
    }
    console.log("did you read focused ?");
  };

  return (
    <div className="w-full h-full p-4">
      <div className="bg-white shadow-lg p-5 rounded-lg w-full">
        <h2 className="text-xl font-semibold">{t("evaluateEmail")}</h2>

        <div className="mb-4 flex items-center space-x-2">
          <label
            htmlFor="profile"
            className="text-sm font-medium text-gray-700"
          >
            {t("profile")}
          </label>
          <select
            id="profile"
            value={selectedProfile}
            onChange={handleProfileChange}
            className="flex-grow rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
            style={{ maxWidth: "250px" }}
          >
            {Object.keys(profiles).map((profile) => (
              <option key={profile} value={profile}>
                {t(profile)}
              </option>
            ))}
          </select>
          <button
            type="button"
            onClick={toggleEditProfile}
            className="px-4 py-2 bg-yellow-600 hover:bg-yellow-700 text-white font-bold rounded"
          >
            {editingProfile ? t("doneEditing") : t("editProfile")}
          </button>
        </div>
        <div>
          {editingProfile && (
            <>
              <div className="mb-4">
                <label className="block text-sm font-medium text-gray-700">
                  {t("valuesToEvaluate")}
                </label>
                <div className="mt-2 flex flex-wrap">
                  {values.map((value) => (
                    <div
                      key={value}
                      className="flex items-center mb-2 mr-2 bg-gray-100 p-2 rounded"
                    >
                      <span className="ml-2 text-gray-700">
                        {t(value.toLowerCase())}
                      </span>
                      {editingProfile && (
                        <button
                          type="button"
                          onClick={() => handleDeleteValue(value)}
                          className="ml-2 text-red-600 hover:text-red-800"
                        >
                          <FontAwesomeIcon icon={faMinusCircle} />
                        </button>
                      )}
                    </div>
                  ))}
                </div>
                {editingProfile && (
                  <button
                    type="button"
                    onClick={() => setAddingValues(!addingValues)}
                    className="mt-2 px-4 py-2 bg-green-600 hover:bg-green-700 text-white font-bold rounded"
                  >
                    {addingValues ? t("doneAddingValues") : t("addValues")}
                  </button>
                )}
                {addingValues && (
                  <div className="flex items-center mt-2">
                    <input
                      type="text"
                      value={newValue}
                      onChange={(e) => setNewValue(e.target.value)}
                      className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                      placeholder={t("addNewValue")}
                    />
                    <button
                      type="button"
                      onClick={handleAddValue}
                      className="ml-2 px-4 py-2 bg-green-600 hover:bg-green-700 text-white font-bold rounded"
                    >
                      {t("add")}
                    </button>
                  </div>
                )}
              </div>
              <div className="mb-4">
                <label className="block text-sm font-medium text-gray-700">
                  {t("stylesToEvaluate")}
                </label>
                <div className="mt-2 flex flex-wrap">
                  {styles.map((style) => (
                    <div
                      key={style}
                      className="flex items-center mb-2 mr-2 bg-gray-100 p-2 rounded"
                    >
                      <span className="ml-2 text-gray-700">
                        {t(style.toLowerCase())}
                      </span>
                      {editingProfile && (
                        <button
                          type="button"
                          onClick={() => handleDeleteStyle(style)}
                          className="ml-2 text-red-600 hover:text-red-800"
                        >
                          <FontAwesomeIcon icon={faMinusCircle} />
                        </button>
                      )}
                    </div>
                  ))}
                </div>
                {editingProfile && (
                  <button
                    type="button"
                    onClick={() => setAddingStyles(!addingStyles)}
                    className="mt-2 px-4 py-2 bg-green-600 hover:bg-green-700 text-white font-bold rounded"
                  >
                    {addingStyles ? t("doneAddingStyles") : t("addStyles")}
                  </button>
                )}
                {addingStyles && (
                  <div className="flex items-center mt-2">
                    <input
                      type="text"
                      value={newStyle}
                      onChange={(e) => setNewStyle(e.target.value)}
                      className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                      placeholder={t("addNewStyle")}
                    />
                    <button
                      type="button"
                      onClick={handleAddStyle}
                      className="ml-2 px-4 py-2 bg-green-600 hover:bg-green-700 text-white font-bold rounded"
                    >
                      {t("add")}
                    </button>
                  </div>
                )}
              </div>
            </>
          )}
        </div>

        <form onSubmit={handleSubmit} className="mt-4">
          <div className="mb-4">
            <label
              htmlFor="emailContent"
              className="block text-sm font-medium text-gray-700"
            >
              {t("emailContent")}
            </label>
            <textarea
              ref={emailContentRef}
              id="emailContent"
              value={emailContent}
              onChange={(e) => setEmailContent(e.target.value)}
              rows="10"
              placeholder={t("typeOrPasteEmailHere")}
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:outline-none focus-visible:ring-2 focus-visible:ring-indigo-500 focus-visible:border-transparent"
              onClick={() => handleFocus(emailContentRef)}
            />
            <div className="flex space-x-2 mt-2">
              <button
                type="button"
                onClick={handlePasteFromClipboardEmailContent}
                data-tip={t("clickToPasteFromClipboard")}
                className="px-2 py-1 bg-gray-200 hover:bg-gray-300 text-gray-700 rounded"
              >
                <FontAwesomeIcon icon={faPaste} />
              </button>
              <button
                type="button"
                onClick={handleCopyToClipboardEmailContent}
                data-tip={t("clickToCopyToClipboard")}
                className="px-2 py-1 bg-gray-200 hover:bg-gray-300 text-gray-700 rounded"
              >
                <FontAwesomeIcon icon={faCopy} />
              </button>
              {!isEvaluating && (
                <button
                  type="submit"
                  className="px-4 py-2 bg-indigo-600 hover:bg-indigo-700 text-white font-bold rounded"
                >
                  {t("evaluateEmail")}
                </button>
              )}
              <Tooltip />
            </div>
          </div>
        </form>
        {evaluationResults && (
          <div ref={enhancedEmailRef} className="mt-5">
            <h3 className="text-lg font-semibold">{t("evaluationResults")}</h3>
            <div className="mt-2 flex flex-wrap">
              {evaluationResults.evaluations.map((evalResult, index) => (
                <>
                  <div className="w-full">
                    <h4 className="text-md font-semibold mt-4">
                      {t("valueEvaluation")}
                    </h4>
                  </div>
                  <div
                    key={index}
                    className="flex items-center mb-2 mr-2 bg-gray-100 p-2 rounded"
                  >
                    <span className="ml-2 text-gray-700">
                      <strong>{t(evalResult.value.toLowerCase())}:</strong>{" "}
                      {t("score")} {evalResult.score} - {evalResult.reason}
                    </span>
                  </div>
                </>
              ))}
              {evaluationResults.styles && (
                <>
                  <div className="w-full">
                    <h4 className="text-md font-semibold mt-4">
                      {t("stylesEvaluation")}
                    </h4>
                  </div>
                  {evaluationResults.styles.map((styleResult, index) => (
                    <div
                      key={index}
                      className="flex items-center mb-2 mr-2 bg-gray-100 p-2 rounded"
                    >
                      <span className="ml-2 text-gray-700">
                        <strong>{t(styleResult.style.toLowerCase())}:</strong>{" "}
                        {t("score")} {styleResult.score} - {styleResult.reason}
                      </span>
                    </div>
                  ))}
                </>
              )}
            </div>
            <h3 className="text-lg font-semibold">{t("enhancedVersion")}</h3>
            <textarea
              ref={enhancedEmailTextareaRef}
              value={enhancedEmailContent}
              onChange={(e) => setEnhancedEmailContent(e.target.value)}
              rows="10"
              placeholder={t("enhancedEmailContent")}
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:outline-none focus-visible:ring-2 focus-visible:ring-indigo-500 focus-visible:border-transparent"
              onClick={() => handleFocus(enhancedEmailTextareaRef)}
            />
            {enhancedEmailEvaluations.length > 0 && (
              <>
                <h4 className="text-md font-semibold mt-4">
                  {t("enhancedEmailEvaluations")}
                </h4>
                <div className="mt-2 flex flex-wrap">
                  {enhancedEmailEvaluations.map((evalResult, index) => (
                    <div
                      key={index}
                      className="flex items-center mb-2 mr-2 bg-gray-100 p-2 rounded"
                    >
                      <span className="ml-2 text-gray-700">
                        <strong>{t(evalResult.value.toLowerCase())}:</strong>{" "}
                        {t("score")} {evalResult.score} - {evalResult.reason}
                      </span>
                    </div>
                  ))}
                </div>
              </>
            )}
            {enhancedEmailStyles.length > 0 && (
              <>
                <h4 className="text-md font-semibold mt-4">
                  {t("enhancedEmailStyles")}
                </h4>
                <div className="mt-2 flex flex-wrap">
                  {enhancedEmailStyles.map((styleResult, index) => (
                    <div
                      key={index}
                      className="flex items-center mb-2 mr-2 bg-gray-100 p-2 rounded"
                    >
                      <span className="ml-2 text-gray-700">
                        <strong>{t(styleResult.style.toLowerCase())}:</strong>{" "}
                        {t("score")} {styleResult.score} - {styleResult.reason}
                      </span>
                    </div>
                  ))}
                </div>
              </>
            )}
            <div className="mt-4 flex items-center">
              <label className="flex items-center">
                <input
                  type="checkbox"
                  checked={makeItLonger}
                  onChange={() => handleCheckboxChange("longer")}
                  className="form-checkbox"
                />
                <span className="ml-2">{t("makeItLonger")}</span>
              </label>
              <label className="flex items-center ml-4">
                <input
                  type="checkbox"
                  checked={makeItShorter}
                  onChange={() => handleCheckboxChange("shorter")}
                  className="form-checkbox"
                />
                <span className="ml-2">{t("makeItShorter")}</span>
              </label>
            </div>
            <div className="mt-4 flex items-center">
              <button
                type="button"
                onClick={handleGenerateBetter}
                className="px-4 py-2 bg-blue-600 hover:bg-blue-700 text-white font-bold rounded"
              >
                {t("generateBetter")}
              </button>
              <button
                type="button"
                onClick={handleCopyToClipboard}
                data-tip={t("clickToCopyToClipboard")}
                className="ml-2 px-2 py-1 bg-gray-200 hover:bg-gray-300 text-gray-700 rounded"
              >
                <FontAwesomeIcon icon={faCopy} />
              </button>
              <button
                type="button"
                onClick={handlePasteFromClipboard}
                data-tip={t("clickToPasteFromClipboard")}
                className="ml-2 px-2 py-1 bg-gray-200 hover:bg-gray-300 text-gray-700 rounded"
              >
                <FontAwesomeIcon icon={faPaste} />
              </button>
            </div>
            <Tooltip />
          </div>
        )}
      </div>
    </div>
  );
}

export default EmailEvaluation;
