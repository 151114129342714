import React, { createContext, useState, useEffect } from "react";
import axios from "./../axiosConfig";
import { API_URL } from "./../config";

export const AuthContext = createContext();

const AuthProvider = ({ children }) => {
  const [authToken, setAuthToken] = useState(
    localStorage.getItem("access_token")
  );
  const [loading, setLoading] = useState(true);
  const [refreshTokenValue, setRefreshToken] = useState(
    localStorage.getItem("refresh_token")
  );

  useEffect(() => {
    const checkTokenExpiration = async () => {
      if (authToken && tokenIsExpired(authToken)) {
        await refreshToken(); // Function call remains the same
      }
      setLoading(false);
    };

    const checkQueryToken = async () => {
      console.log("Executing checkQueryToken");
      const params = new URLSearchParams(window.location.search);
      const token = params.get("token");
      console.log("Token from URL:", token);
      if (token) {
        try {
          console.log("Sending request to validate token");
          const response = await axios.get(`${API_URL}/test-auth`, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
            withCredentials: true, // Ensure credentials are included
          });
          console.log("Response from /test-auth:", response);
          if (response.data.message === "success") {
            console.log("Token is valid, logging in");
            login(token, token); // For simplicity, use the same token for refresh
          } else {
            console.log("Token validation failed");
            setLoading(false);
          }
        } catch (error) {
          console.error("Error validating query token:", error);
          setLoading(false);
        }
      } else {
        console.log("No token in URL, checking token expiration");
        checkTokenExpiration();
      }
    };

    if (!authToken) {
      console.log("No authToken, executing checkQueryToken");
      checkQueryToken();
    } else {
      console.log("authToken present, checking expiration");
      checkTokenExpiration();
    }
  }, [authToken]);

  const login = (accessToken, refreshToken) => {
    console.log("Logging in, setting tokens");
    localStorage.setItem("access_token", accessToken);
    localStorage.setItem("refresh_token", refreshToken);
    setAuthToken(accessToken);
    setRefreshToken(refreshToken); // Update this function call
  };

  const logout = () => {
    console.log("Logging out, clearing tokens");
    localStorage.removeItem("access_token");
    localStorage.removeItem("refresh_token"); // Also remove refresh token on logout
    setAuthToken(null);
    setRefreshToken(null); // Clear state
  };

  const refreshToken = async () => {
    console.log("Executing refreshToken");
    try {
      const refreshTokenStored = localStorage.getItem("refresh_token");
      console.log("Stored refreshToken:", refreshTokenStored);

      // Construct the data in application/x-www-form-urlencoded format
      const data = `refresh_token=${encodeURIComponent(refreshTokenStored)}`;

      const response = await axios.post(`${API_URL}/refresh`, data, {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
        withCredentials: true, // Ensure credentials are included
      });

      const { access_token } = response.data;
      console.log("New access token received:", access_token);
      localStorage.setItem("access_token", access_token);
      setAuthToken(access_token);
      return access_token;
    } catch (error) {
      console.error("Error refreshing token:", error);
      logout();
    }
  };

  const tokenIsExpired = (token) => {
    // Implement your token expiration logic here
    console.log("Checking if token is expired");
    return false; // Placeholder for token expiration logic
  };

  return (
    <AuthContext.Provider
      value={{ authToken, login, logout, refreshToken, loading }}
    >
      {!loading && children}
    </AuthContext.Provider>
  );
};

export default AuthProvider;
