import React, { useContext, useEffect, useRef, useState } from "react";
import { AuthContext } from "../contexts/AuthContext";
import axios from "./../axiosConfig";
import { Link, Route, Routes, useNavigate } from "react-router-dom";
import Translate from "./Translate";
import EmailEvaluation from "./EmailEvaluation";
import SpellCheck from "./SpellCheck";
import Profile from "./Profile";
import { API_URL } from "./../config";
import { useTranslation } from "react-i18next";
import i18n from "../i18n";
import "./styles/BurgerMenu.css"; // Import your custom CSS

function Dashboard() {
  const { t } = useTranslation();
  const { authToken, refreshToken, logout } = useContext(AuthContext);
  const navigate = useNavigate();
  const [menuOpen, setMenuOpen] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  const logoutButtonRef = useRef(null);

  useEffect(() => {
    if (!authToken) {
      navigate("/login");
      return;
    }

    const fetchData = async () => {
      try {
        const response = await axios.get(`${API_URL}/test-auth`, {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        });
        console.log(response.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, [authToken, navigate]);

  useEffect(() => {
    const intervalId = setInterval(async () => {
      try {
        const newToken = await refreshToken(); // This now also updates the context
        console.log("New token:", newToken);
      } catch (error) {
        console.error("Failed to refresh token:", error);
      }
    }, 120000); // 2 minutes

    return () => clearInterval(intervalId); // Cleanup on unmount
  }, [refreshToken]);

  const handleLogout = () => {
    // Clear the authentication tokens
    logout();
    // Redirect to the login page
    navigate("/login");
  };

  const changeLanguage = (event) => {
    const language = event.target.value;
    i18n.changeLanguage(language);
  };

  useEffect(() => {
    console.log("this is a language debug");
    console.log(t("Home")); // Should log "Home is home" if translations are loaded correctly
  }, [t]);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  const closeMenu = () => {
    setMenuOpen(false);
  };

  useEffect(() => {
    const checkVisibility = () => {
      if (logoutButtonRef.current) {
        const rect = logoutButtonRef.current.getBoundingClientRect();
        const isVisible =
          rect.bottom <= window.innerHeight && rect.right <= window.innerWidth;
        setIsMobile(!isVisible);
      }
    };

    window.addEventListener("resize", checkVisibility);
    checkVisibility(); // Initial check

    return () => window.removeEventListener("resize", checkVisibility);
  }, []);

  const menuItems = (
    <div className="flex flex-col h-full">
      <ul className="flex-grow">
        <li>
          <Link
            to=""
            className="text-l md:text-l font-bold font-roboto text-primaryBackground hover:bg-accent1 py-2 px-5 rounded-lg flex items-left justify-left"
            onClick={closeMenu}
          >
            {t("Home")}
          </Link>
        </li>
        <li>
          <Link
            to="evaluate-email"
            className="text-l md:text-l font-bold font-roboto text-primaryBackground hover:bg-accent1 py-2 px-5 rounded-lg flex items-left justify-left"
            onClick={closeMenu}
          >
            {t("Evaluate Email")}
          </Link>
        </li>
        <li>
          <Link
            to="spell-check"
            className="text-l md:text-l font-bold font-roboto text-primaryBackground hover:bg-accent1 py-2 px-5 rounded-lg flex items-left justify-left"
            onClick={closeMenu}
          >
            {t("Spell Check")}
          </Link>
        </li>
        <li className="mb-auto">
          <Link
            to="translate"
            className="text-l md:text-l font-bold font-roboto text-primaryBackground hover:bg-accent1 py-2 px-5 rounded-lg flex items-left justify-left"
            onClick={closeMenu}
          >
            {t("Translate")}
          </Link>
        </li>
      </ul>
      {isMobile && (
        <div className="mt-auto mb-4 pt-52">
          <ul>
            <li className="mb-2">
              <select
                onChange={changeLanguage}
                value={i18n.language}
                className="text-m md:text-l font-roboto text-primaryBackground bg-tertiary border border-primaryBackground rounded-lg py-2 px-5 flex items-center justify-center focus:outline-none focus:ring-0"
                style={{ height: "40px" }} // Adjust height to match the buttons and links
              >
                <option value="en">English</option>
                <option value="es">Español</option>
                <option value="fr">Français</option>
                <option value="de">Deutsch</option>
                <option value="it">Italiano</option>
                <option value="pt">Português</option>
                <option value="pl">Polski</option>
              </select>
            </li>
            <li>
              <button
                onClick={handleLogout}
                className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded bm-item"
                style={{ height: "40px" }} // Adjust height to match the buttons and links
              >
                Logout
              </button>
            </li>
          </ul>
        </div>
      )}
    </div>
  );

  return (
    <div className="flex flex-col w-full h-screen overflow-hidden bg-primaryBackground">
      <nav className="navbar p-5">
        <div className="navbar-left">
          <div
            className="bm-burger-button"
            onClick={toggleMenu}
            style={{ display: isMobile ? "block" : "none" }}
          >
            <div className="bm-burger-bars" />
            <div className="bm-burger-bars" />
            <div className="bm-burger-bars" />
          </div>
          <h1 className="text-2xl md:text-3xl font-bold font-roboto text-primary pl-10">
            {t("Dashboard")}
          </h1>
          {!isMobile && (
            <ul className="navbar-links pl-24">
              <li>
                <Link
                  to=""
                  className="text-xl md:text-xl font-bold font-roboto text-primaryText hover:text-secondaryText hover:bg-accent1 py-2 px-5 rounded-3xl flex items-center justify-center"
                >
                  {t("Home")}
                </Link>
              </li>
              <li>
                <Link
                  to="evaluate-email"
                  className="text-xl md:text-xl font-bold font-roboto text-primaryText hover:text-secondaryText hover:bg-accent1 py-2 px-5 rounded-3xl flex items-center justify-center"
                >
                  {t("Evaluate Email")}
                </Link>
              </li>
              <li>
                <Link
                  to="spell-check"
                  className="text-xl md:text-xl font-bold font-roboto text-primaryText hover:text-secondaryText hover:bg-accent1 py-2 px-5 rounded-3xl flex items-center justify-center"
                >
                  {t("Spell Check")}
                </Link>
              </li>
              <li>
                <Link
                  to="translate"
                  className="text-xl md:text-xl font-bold font-roboto text-primaryText hover:text-secondaryText hover:bg-accent1 py-2 px-5 rounded-3xl flex items-center justify-center"
                >
                  {t("Translate")}
                </Link>
              </li>
            </ul>
          )}
        </div>
        <div className={`bm-menu ${menuOpen ? "open" : ""}`}>
          <div className="bm-cross-button" onClick={closeMenu}>
            <div className="bm-cross" />
          </div>
          <div className="bm-item-list">{menuItems}</div>
        </div>
        {!isMobile && (
          <div className="navbar-right">
            <select
              onChange={changeLanguage}
              value={i18n.language}
              className="w-56 text-m md:text-l font-roboto text-primaryText bg-primaryBackground border border-tertiary rounded-lg py-2 px-5 flex items-center justify-center focus:outline-none focus:ring-0"
            >
              <option value="en">English</option>
              <option value="es">Español</option>
              <option value="fr">Français</option>
              <option value="de">Deutsch</option>
              <option value="it">Italiano</option>
              <option value="pt">Português</option>
              <option value="pl">Polski</option>
            </select>
            <button
              ref={logoutButtonRef}
              onClick={handleLogout}
              className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded desktop-item navbar-logout"
            >
              Logout
            </button>
          </div>
        )}
      </nav>
      <div className="flex flex-col overflow-auto h-full">
        <Routes>
          <Route
            path=""
            element={
              <div className="flex justify-center items-start min-h-screen w-full">
                <Profile />
              </div>
            }
          />
          <Route
            path="translate"
            element={
              <div className="flex justify-center items-start min-h-screen w-full">
                <Translate />
              </div>
            }
          />
          <Route
            path="evaluate-email"
            element={
              <div className="flex justify-center items-start min-h-screen w-full">
                <EmailEvaluation />
              </div>
            }
          />
          <Route
            path="spell-check"
            element={
              <div className="flex justify-center items-start min-h-screen w-full">
                <SpellCheck />
              </div>
            }
          />
        </Routes>
      </div>
    </div>
  );
}

export default Dashboard;
