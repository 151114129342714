import React, { useState, useContext, useEffect } from "react";
import axios from "./../axiosConfig";
import { AuthContext } from "../contexts/AuthContext";
import { useNavigate } from "react-router-dom";
import { API_URL } from "./../config";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useTranslation } from "react-i18next";
import Select from "react-select";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import i18n from "../i18n";

function LoginChromeExtension() {
  const { t } = useTranslation();
  const [formMode, setFormMode] = useState("login"); // 'login', 'register', or 'forgotPassword'
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [email, setEmail] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [plan, setPlan] = useState("");
  const [country, setCountry] = useState(null);
  const [address, setAddress] = useState("");
  const [dob, setDob] = useState(null);
  const [countries, setCountries] = useState([]);
  const [plans, setPlans] = useState({});
  const [error, setError] = useState(null);
  const [showPassword, setShowPassword] = useState(false);
  const [isUnder18, setIsUnder18] = useState(false);
  const [parentalConsent, setParentalConsent] = useState(false);

  const { login } = useContext(AuthContext);
  const navigate = useNavigate();

  const changeLanguage = (event) => {
    const language = event.target.value;
    i18n.changeLanguage(language);
  };

  const fetchCountries = async () => {
    try {
      const response = await axios.get("https://restcountries.com/v3.1/all");
      const countryList = response.data.map((country) => ({
        label: country.name.common,
        value: country.cca2.toLowerCase(),
      }));
      setCountries(countryList);
    } catch (error) {
      console.error("Failed to fetch countries:", error);
      setError(t("failedToLoadCountries"));
    }
  };

  const fetchPlans = async () => {
    try {
      const response = await axios.get(`${API_URL}/plans`);
      setPlans(response.data);
    } catch (error) {
      console.error("Failed to fetch plans:", error);
      setError(t("failedToLoadPlans"));
    }
  };

  useEffect(() => {
    fetchCountries();
    fetchPlans();
  }, []);

  const getPlanOptions = (country) => {
    if (!country) return [];
    const countryCode = country.value.toLowerCase();
    if (countryCode === "fr") return plans.fr || [];
    if (countryCode === "uk") return plans.uk || [];
    if (["be", "de", "it", "nl", "es", "pt"].includes(countryCode))
      return plans.eu || [];
    return plans.us || [];
  };

  const handleLoginSubmit = async (event) => {
    event.preventDefault();
    try {
      const response = await axios.post(
        `${API_URL}/token`,
        new URLSearchParams({ username, password }),
        { headers: { "Content-Type": "application/x-www-form-urlencoded" } }
      );

      const { access_token, refresh_token } = response.data;
      login(access_token, refresh_token);
      toast.success(t("loginSuccessful"));
      navigate("/chromeextension");
    } catch (error) {
      console.error(t("loginFailed"), error);
      toast.error(t("loginFailed"));
    }
  };

  const handleRegisterSubmit = async (event) => {
    event.preventDefault();

    console.log("Form Data:", {
      password,
      confirmPassword,
      email,
      firstName,
      lastName,
      plan,
      country,
      address,
      dob,
    });

    if (password !== confirmPassword) {
      toast.error(t("passwordsDoNotMatch"));
      return;
    }

    if (!validatePassword(password)) {
      toast.error(
        <div>
          {t("Password must:")}
          <ul className="list-disc ml-4">
            <li>{t("Be at least 8 characters long.")}</li>
            <li>{t("Contain at least one lowercase letter.")}</li>
            <li>{t("Contain at least one uppercase letter.")}</li>
            <li>{t("Contain at least one digit.")}</li>
            <li>
              {t(
                "Contain at least one special character from the set @$!%*?&."
              )}
            </li>
          </ul>
        </div>
      );
      return;
    }

    if (!validateEmail(email)) {
      toast.error(t("invalidEmail"));
      return;
    }

    if (
      !email ||
      !firstName ||
      !lastName ||
      !plan ||
      !country ||
      !address ||
      !dob
    ) {
      console.log("Missing Fields:", {
        email: !email,
        firstName: !firstName,
        lastName: !lastName,
        plan: !plan,
        country: !country,
        address: !address,
        dob: !dob,
      });
      toast.error(t("allFieldsRequired"));
      return;
    }

    if (isUnder18 && !parentalConsent) {
      toast.error(t("parentalConsentRequired"));
      return;
    }

    try {
      const response = await axios.post(
        `${API_URL}/register`,
        {
          email,
          password,
          firstName,
          lastName,
          plan,
          country: country ? country.value : "",
          address,
          dob,
        },
        { headers: { "Content-Type": "application/json" } }
      );
      console.log(t("registrationSuccessful"), response.data);
      toast.success(t("registrationSuccessful"));
      setFormMode("login");
    } catch (error) {
      console.error(t("registrationFailed"), error);
      toast.error(t("registrationFailed"));
    }
  };

  const handleForgotPasswordSubmit = async (event) => {
    event.preventDefault();

    if (!email) {
      toast.error(t("allFieldsRequired"));
      return;
    }

    if (!validateEmail(email)) {
      toast.error(t("invalidEmail"));
      return;
    }

    try {
      const response = await axios.post(
        `${API_URL}/password-reset-request`,
        new URLSearchParams({ email }),
        { headers: { "Content-Type": "application/x-www-form-urlencoded" } }
      );

      console.log(t("passwordResetRequestSuccessful"), response.data);
      toast.success(t("passwordResetRequestSuccessful"));
      setFormMode("login");
    } catch (error) {
      console.error(t("passwordResetRequestFailed"), error);
      toast.error(t("passwordResetRequestFailed"));
    }
  };

  const validatePassword = (password) => {
    const passwordRegex =
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&.])[A-Za-z\d@$!%*?&.]{8,}$/;
    return passwordRegex.test(password);
  };

  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const handleDateChange = (date) => {
    setDob(date ? date.toISOString() : "");

    const today = new Date();
    const birthDate = new Date(date);
    let age = today.getFullYear() - birthDate.getFullYear();
    const m = today.getMonth() - birthDate.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
      age--;
    }
    setIsUnder18(age < 18);
  };

  return (
    <div className="min-h-screen flex items-center justify-center bg-gray-100 px-10 py-12 rounded-lg">
      <div className="w-full max-w-lg">
        {formMode === "login" ? (
          <div className="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4">
            <div className="flex justify-between items-center mb-2">
              <h2 className="block text-gray-700 text-lg font-bold mb-2">
                {t("login")}
              </h2>
              <select
                onChange={changeLanguage}
                value={i18n.language}
                className="mr-4 p-2"
              >
                <option value="en">English</option>
                <option value="es">Español</option>
                <option value="fr">Français</option>
                <option value="de">Deutsch</option>
                <option value="it">Italiano</option>
                <option value="pt">Português</option>
                <option value="pl">Polski</option>
              </select>
            </div>
            <form onSubmit={handleLoginSubmit} className="mb-4">
              <div className="mb-4">
                <input
                  type="text"
                  value={username}
                  onChange={(e) => setUsername(e.target.value)}
                  placeholder={t("username")}
                  className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                />
              </div>
              <div className="mb-6 relative">
                <input
                  type={showPassword ? "text" : "password"}
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  placeholder={t("password")}
                  className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 mb-3 leading-tight focus:outline-none focus:shadow-outline"
                />
                <button
                  type="button"
                  onClick={() => setShowPassword(!showPassword)}
                  className="absolute inset-y-0 right-0 pr-3 flex items-center text-sm leading-5"
                >
                  {showPassword ? t("Hide") : t("Show")}
                </button>
              </div>
              <div className="flex items-center justify-center w-full">
                <button
                  type="submit"
                  className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                >
                  {t("login")}
                </button>
              </div>
              <div className="flex items-center justify-center w-full">
                <button
                  type="button"
                  onClick={() => setFormMode("register")}
                  className="inline-block align-baseline font-bold text-sm text-blue-500 hover:text-blue-800"
                >
                  {t("needAccount")}
                </button>
              </div>
              <div className="flex items-center justify-center w-full">
                <button
                  type="button"
                  onClick={() => setFormMode("forgotPassword")}
                  className="inline-block align-baseline font-bold text-sm text-blue-500 hover:text-blue-800"
                >
                  {t("forgotPasswordLink")}
                </button>
              </div>
            </form>
          </div>
        ) : formMode === "register" ? (
          <div className="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4">
            <div className="flex justify-between items-center mb-2">
              <h2 className="block text-gray-700 text-lg font-bold mb-2">
                {t("register")}
              </h2>
              <select
                onChange={changeLanguage}
                value={i18n.language}
                className="mr-4 p-2"
              >
                <option value="en">English</option>
                <option value="es">Español</option>
                <option value="fr">Français</option>
                <option value="de">Deutsch</option>
                <option value="it">Italiano</option>
                <option value="pt">Português</option>
                <option value="pl">Polski</option>
              </select>
            </div>
            <form onSubmit={handleRegisterSubmit} className="space-y-4 mb-4">
              <input
                type="text"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                placeholder={t("email")}
                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              />
              <div className="relative">
                <input
                  type={showPassword ? "text" : "password"}
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  placeholder={t("password")}
                  className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                />
                <button
                  type="button"
                  onClick={() => setShowPassword(!showPassword)}
                  className="absolute inset-y-0 right-0 pr-3 flex items-center text-sm leading-5"
                >
                  {showPassword ? t("Hide") : t("Show")}
                </button>
              </div>
              <div className="relative">
                <input
                  type={showPassword ? "text" : "password"}
                  value={confirmPassword}
                  onChange={(e) => setConfirmPassword(e.target.value)}
                  placeholder={t("confirmPassword")}
                  className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                />
              </div>
              <input
                type="text"
                value={firstName}
                onChange={(e) => setFirstName(e.target.value)}
                placeholder={t("firstName")}
                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              />
              <input
                type="text"
                value={lastName}
                onChange={(e) => setLastName(e.target.value)}
                placeholder={t("lastName")}
                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              />
              <Select
                options={countries}
                onChange={(selectedOption) => {
                  setCountry(selectedOption ? selectedOption : "");
                  setPlan(""); // Reset plan when country changes
                }}
                value={country}
                className="text-base"
                isClearable
                isSearchable
                placeholder={t("country")}
              />
              {country && (
                <select
                  value={plan}
                  onChange={(e) => setPlan(e.target.value)}
                  className="shadow border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                >
                  {getPlanOptions(country).map((planOption, index) => (
                    <option key={index} value={planOption}>
                      {planOption}
                    </option>
                  ))}
                </select>
              )}
              <input
                type="text"
                value={address}
                onChange={(e) => setAddress(e.target.value)}
                placeholder={t("address")}
                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              />
              <DatePicker
                selected={dob ? new Date(dob) : null}
                onChange={handleDateChange}
                className="mt-1 p-2 w-full border rounded"
                showYearDropdown
                yearDropdownItemNumber={100}
                scrollableYearDropdown
                placeholderText={t("dob")}
              />
              {isUnder18 && (
                <div className="flex items-center">
                  <input
                    type="checkbox"
                    checked={parentalConsent}
                    onChange={(e) => setParentalConsent(e.target.checked)}
                    className="mr-2"
                  />
                  <label className="text-gray-700">
                    {t("parentalConsent")}
                  </label>
                </div>
              )}
              <div className="flex items-center justify-between">
                <button
                  type="submit"
                  className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                >
                  {t("register")}
                </button>
                <button
                  type="button"
                  onClick={() => setFormMode("login")}
                  className="inline-block align-baseline font-bold text-sm text-blue-500 hover:text-blue-800"
                >
                  {t("alreadyRegistered")}
                </button>
              </div>
            </form>
          </div>
        ) : (
          <div className="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4">
            <div className="flex justify-between items-center mb-2">
              <h2 className="block text-gray-700 text-lg font-bold mb-2">
                {t("forgotPassword")}
              </h2>
              <select
                onChange={changeLanguage}
                value={i18n.language}
                className="mr-4 p-2"
              >
                <option value="en">English</option>
                <option value="es">Español</option>
                <option value="fr">Français</option>
                <option value="de">Deutsch</option>
                <option value="it">Italiano</option>
                <option value="pt">Português</option>
                <option value="pl">Polski</option>
              </select>
            </div>
            <form onSubmit={handleForgotPasswordSubmit} className="mb-4">
              <div className="mb-4">
                <input
                  type="text"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  placeholder={t("email")}
                  className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                />
              </div>
              <div className="flex items-center justify-between">
                <button
                  type="submit"
                  className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                >
                  {t("submit")}
                </button>
                <button
                  type="button"
                  onClick={() => setFormMode("login")}
                  className="inline-block align-baseline font-bold text-sm text-blue-500 hover:text-blue-800"
                >
                  {t("backToLogin")}
                </button>
              </div>
            </form>
          </div>
        )}
      </div>
    </div>
  );
}

export default LoginChromeExtension;
