import React, { useState, useEffect, useContext } from "react";
import axios from "./../axiosConfig";
import { AuthContext } from "../contexts/AuthContext";
import { API_URL } from "./../config";
import Autocomplete from "react-google-autocomplete";
import Select from "react-select";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useTranslation } from "react-i18next"; // Import the useTranslation hook

function Profile() {
  const { t } = useTranslation(); // Use the useTranslation hook
  const { authToken } = useContext(AuthContext);
  const [profile, setProfile] = useState({
    plan: "",
    firstName: "",
    lastName: "",
    country: "",
    address: "",
    dob: "",
    registrationDate: "", // Assuming this field is part of your profile model
  });
  const [editableProfile, setEditableProfile] = useState({ ...profile });
  const [countries, setCountries] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [editMode, setEditMode] = useState(false);

  useEffect(() => {
    fetchProfile();
  }, [authToken]);

  const fetchCountries = async () => {
    try {
      const response = await axios.get("https://restcountries.com/v3.1/all");
      const countryList = response.data.map((country) => ({
        label: country.name.common,
        value: country.cca2.toLowerCase(),
      }));
      setCountries(countryList);
    } catch (error) {
      console.error("Failed to fetch countries:", error);
      setError(t("failedToLoadCountries"));
    }
  };

  const fetchProfile = async () => {
    setLoading(true);
    try {
      const response = await axios.get(`${API_URL}/profile`, {
        headers: {
          Authorization: `Bearer ${authToken}`,
          accept: "application/json",
        },
      });
      const profileData = {
        ...response.data,
        dob: response.data.dob ? new Date(response.data.dob) : "", // Convert to Date object
      };
      setProfile(profileData);
      setEditableProfile(profileData);
      fetchCountries();
    } catch (error) {
      console.error("Error fetching profile:", error);
      setError(t("failedToFetchProfile"));
    } finally {
      setLoading(false);
    }
  };

  const handleInputChange = (field, value) => {
    setEditableProfile((prev) => ({
      ...prev,
      [field]: value,
    }));
  };

  const handleDateChange = (date) => {
    handleInputChange("dob", date ? date.toISOString() : "");
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);
    try {
      const response = await axios.post(`${API_URL}/profile`, editableProfile, {
        headers: {
          Authorization: `Bearer ${authToken}`,
          "Content-Type": "application/json",
        },
      });
      const updatedProfile = {
        ...response.data,
        dob: response.data.dob ? new Date(response.data.dob) : "", // Convert to Date object
      };
      setProfile(updatedProfile); // Update the profile state with the saved data
      setEditMode(false);
    } catch (error) {
      console.error("Error updating profile:", error);
      setError(t("failedToUpdateProfile"));
    } finally {
      setLoading(false);
    }
  };

  const formatDate = (dateString) => {
    const options = { year: "numeric", month: "long", day: "numeric" };
    return new Date(dateString).toLocaleDateString(undefined, options);
  };

  const renderProfileView = () => (
    <div className="bg-white shadow-lg p-5 rounded-lg w-full">
      <h2 className="text-2xl font-semibold mb-5">{t("Profile")}</h2>
      {Object.entries(profile).map(([key, value]) => (
        <div className="mb-4" key={key}>
          <label className="block text-sm font-medium text-gray-700 mb-1">
            {t(key)}
          </label>
          <p className="mt-1 text-gray-900 text-base">
            {key === "registrationDate" || key === "dob"
              ? formatDate(value)
              : value}
          </p>
        </div>
      ))}
      <button
        onClick={() => {
          setEditableProfile(profile);
          setEditMode(true);
        }}
        className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
      >
        {t("editProfile")}
      </button>
    </div>
  );

  const renderEditForm = () => (
    <div className="bg-white shadow-lg p-5 rounded-lg w-full">
      <form onSubmit={handleSubmit}>
        <h2 className="text-2xl font-semibold mb-5">{t("editProfile")}</h2>
        {Object.entries(editableProfile).map(([key, value]) => (
          <div className="mb-4" key={key}>
            <label className="block text-sm font-medium text-gray-700 mb-1">
              {t(key)}
            </label>
            {key === "dob" ? (
              <DatePicker
                selected={value ? new Date(value) : null}
                onChange={handleDateChange}
                className="mt-1 p-2 w-full border rounded"
                showYearDropdown
                yearDropdownItemNumber={100}
                scrollableYearDropdown
              />
            ) : key === "country" ? (
              <Select
                options={countries}
                onChange={(selectedOption) =>
                  handleInputChange(
                    "country",
                    selectedOption ? selectedOption.value : ""
                  )
                }
                value={countries.find(
                  (option) => option.value === editableProfile.country
                )}
                className="text-base mt-1"
                isClearable
                isSearchable
                placeholder={t("country")}
              />
            ) : key === "address" ? (
              <Autocomplete
                apiKey="AIzaSyAOggHXBp1ky0rfbQuzcwsaq1efbHo1Mog"
                onPlaceSelected={(place) =>
                  handleInputChange("address", place.formatted_address)
                }
                defaultValue={value}
                className="mt-1 p-2 w-full border rounded"
                options={{
                  types: ["address"],
                  componentRestrictions: { country: editableProfile.country },
                }}
              />
            ) : key === "registrationDate" ? (
              <p className="mt-1 text-gray-900 text-base">
                {formatDate(value)}
              </p> // Render as non-editable text
            ) : (
              <input
                type="text"
                name={key}
                value={value}
                onChange={(e) => handleInputChange(key, e.target.value)}
                className="mt-1 p-2 w-full border rounded"
              />
            )}
          </div>
        ))}
        <div className="flex justify-end space-x-4">
          <button
            type="button"
            onClick={() => setEditMode(false)}
            className="bg-gray-500 hover:bg-gray-700 text-white font-bold py-2 px-4 rounded"
          >
            {t("cancel")}
          </button>
          <button
            type="submit"
            className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
          >
            {t("saveChanges")}
          </button>
        </div>
      </form>
    </div>
  );

  if (loading) {
    return <div>{t("loading")}</div>;
  }

  if (error) {
    return <div>{error}</div>;
  }

  return (
    <div className="w-full h-full p-4 px-8">
      {editMode ? renderEditForm() : renderProfileView()}
    </div>
  );
}

export default Profile;
