import React, { useState, useContext } from "react";
import axios from "./../axiosConfig";
import { AuthContext } from "../contexts/AuthContext";
import { API_URL } from "./../config";
import { useTranslation } from "react-i18next";

function SpellCheck() {
  const { t } = useTranslation();
  const { authToken } = useContext(AuthContext);
  const [text, setText] = useState("");
  const [result, setResult] = useState(null);
  const [expandedIndex, setExpandedIndex] = useState(null);

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      const response = await axios.post(
        `${API_URL}/api/spell_checkV2`,
        { text },
        {
          headers: {
            Authorization: `Bearer ${authToken}`,
            "Content-Type": "application/json",
          },
        }
      );
      setResult(response.data);
      console.log("I have fetched");
      console.log(response.data);
    } catch (error) {
      console.error("Spell check failed:", error);
      setResult(null);
    }
  };

  const toggleExplanation = (index) => {
    setExpandedIndex(expandedIndex === index ? null : index);
  };

  return (
    <div className="w-full h-full p-4">
      <div className="bg-white shadow-lg p-5 rounded-lg w-full">
        <h2 className="text-xl font-semibold">{t("spellCheck")}</h2>
        {!result ? (
          <form onSubmit={handleSubmit} className="mt-4">
            <div className="mb-4">
              <label
                htmlFor="text"
                className="block text-sm font-medium text-gray-700"
              >
                {t("enterText")}
              </label>
              <textarea
                id="text"
                value={text}
                onChange={(e) => setText(e.target.value)}
                rows="10"
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
              />
            </div>
            <button
              type="submit"
              className="px-4 py-2 bg-indigo-600 hover:bg-indigo-700 text-white font-bold rounded"
            >
              {t("checkSpelling")}
            </button>
          </form>
        ) : (
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mt-5">
            <div>
              <h4 className="text-md font-semibold">
                {t("originalWithCorrections")}
              </h4>
              <div
                className="bg-gray-100 p-3 rounded-lg"
                dangerouslySetInnerHTML={{ __html: result.original_html }}
              />
            </div>
            <div>
              <h4 className="text-md font-semibold">{t("explanation")}</h4>
              <div className="bg-gray-100 p-3 rounded-lg">
                {result.explanation.map((item, index) => (
                  <div key={index} className="mb-2">
                    <p>
                      <strong>{t("mistake")}:</strong> {item.mistake} ->{" "}
                      <strong>{t("correction")}:</strong> {item.correction}
                      <button
                        onClick={() => toggleExplanation(index)}
                        className="ml-2 text-blue-500"
                      >
                        {expandedIndex === index ? "▼" : "▶"}
                      </button>
                    </p>
                    {expandedIndex === index && (
                      <p className="mt-1">
                        <strong>{t("reason")}:</strong> {item.reason}
                      </p>
                    )}
                  </div>
                ))}
              </div>
            </div>
          </div>
        )}
        {result && (
          <div className="mt-5">
            <h4 className="text-md font-semibold mt-4">{t("correctedText")}</h4>
            <p className="mt-1">{result.corrected_text}</p>
          </div>
        )}
      </div>
    </div>
  );
}

export default SpellCheck;
